import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useForm, SubmitHandler } from "react-hook-form";
import { BASE_URL_TWO } from "Constant/Constant";
import axios from "axios";

type Inputs = {
    name: string; // Renamed to `name` for clarity
    amount: number; // Updated to `amount`
    purpose: string; // Updated to `purpose`
};

export default function AddCorporateTopup(props: { open: any; setOpen: any;fetchTopups:any }) {
    const { setOpen, open, fetchTopups } = props;
    const cancelButtonRef = useRef(null);
    const [addUserLoader, setAddUserLoader] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset } = useForm<Inputs>();

    const now = Date.now(); // Unix timestamp in milliseconds


    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setAddUserLoader(true);

        const requestBody = {
            corporate_name: data.name,
            amount: data.amount,
            purpose_of_payment: data.purpose,
            epoch_time: now
        }
        

        try {
            await axios.post(`${BASE_URL_TWO}corporate-topup`, requestBody, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });

            setAddUserLoader(false);
            setOpen(false);
            fetchTopups();
            toast.success("Transaction added successfully!");
            reset();
        } catch (error) {
            setAddUserLoader(false);

            // Improved error handling for different cases
            if (axios.isAxiosError(error) && error.response) {
                const errorMessage = error.response.data?.message || "Failed to add transaction.";
                toast.error(errorMessage);
            } else {
                toast.error("Something went wrong. Please try again.");
            }

            console.error("Error:", error);
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden bg-white rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <h3 className="text-2xl font-bold tracking-tight text-teal-500 sm:text-4xl mb-4">
                                            Add Payment Transaction
                                        </h3>

                                        {/* Name Field */}
                                        <div className="sm:col-span-4">
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Name
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="name"
                                                    type="text"
                                                    className="w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                                                    placeholder="John Doe"
                                                    {...register("name", { required: "Name is required" })}
                                                />
                                            </div>
                                            {errors.name && <span className="text-red-500">{errors.name.message}</span>}
                                        </div>

                                        {/* Amount Field */}
                                        <div className="mt-3 sm:col-span-4">
                                            <label
                                                htmlFor="amount"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Amount
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="amount"
                                                    type="number"
                                                    className="w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                                                    placeholder="Enter amount"
                                                    {...register("amount", {
                                                        required: "Amount is required",
                                                        min: { value: 1, message: "Amount must be greater than 0" }
                                                    })}
                                                />
                                            </div>
                                            {errors.amount && <span className="text-red-500">{errors.amount.message}</span>}
                                        </div>

                                        {/* Purpose Field */}
                                        <div className="mt-3 sm:col-span-4">
                                            <label
                                                htmlFor="purpose"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Purpose
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="purpose"
                                                    type="text"
                                                    className="w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                                                    placeholder="Enter purpose"
                                                    {...register("purpose", { required: "Purpose is required" })}
                                                />
                                            </div>
                                            {errors.purpose && <span className="text-red-500">{errors.purpose.message}</span>}
                                        </div>
                                    </div>

                                    {/* Buttons */}
                                    {addUserLoader ? (
                                        <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
                                                disabled
                                            >
                                                Loading...
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <input
                                                type="submit"
                                                className="inline-flex w-full cursor-pointer justify-center rounded-md bg-teal-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-600 sm:ml-3 sm:w-auto"
                                                value="Add Payment"
                                            />
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    )}
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
