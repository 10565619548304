import { useState, useEffect } from "react";
import { BASE_URL, BASE_URL_TWO, decryptObject, machineTableData } from "Constant/Constant";
import MachineTable from "./components/MachinesTable";
import TableLoader from "components/TableLoader/TableLoader";
import axios from "axios";

const Machines = () => {
  //localStorage
  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  const [machineLoader, setMachineLoader] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [machineStatusLoader, setMachineStatusLoader] = useState(false);
  const [machineStatusData, setMachineStatusData] = useState([]);

  // useEffect(() => {
  //   //list
  //   setMachineLoader(true);
  //   const data = {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },

  //     body: JSON.stringify({
  //       request: {
  //         method: "getMachinesByCompany",
  //         data: {
  //           company_code: localStorageData.user.company_code,
  //         },
  //       },
  //     }),
  //   };
  //   const result = fetch(`${BASE_URL}machines`, data)
  //     .then((response: any) => response.json(response))
  //     .catch((error) => console.log("error", error));
  //   result.then((data) => {
  //     setMachineLoader(false);
  //     setMachineData(data?.response?.data?.machines);
  //   });
  // }, []);


  useEffect(() => {
    //list
    setMachineStatusLoader(true);
    const data = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        request: {
          method: "getMachineStatus",
        },
      }),
    };
    const result = fetch(`${BASE_URL}machines`, data)
      .then((response: any) => response.json(response))
      .catch((error) => console.log("error", error));
    result.then(async (data) => {
      setMachineStatusLoader(false);
      setMachineStatusData(data?.response?.data?.machine_status);
      console.log("testing",data?.response?.data)
    });
  }, []);

  useEffect(() => {
    setMachineLoader(true);
    axios.get(`${BASE_URL_TWO}allMachines`).then((res) => {
      setMachineLoader(false);
      setMachineData(res.data.data);
    }).catch((err) => {
      setMachineLoader(false);
      console.log(err);
    })
  }, []);

  const machineDetails = localStorageData.user.machines && machineData && machineData.filter((e: any) => {
    return localStorageData.user.machines.some((f: any) => {
      return f.machine_code === e.machine_code;
    });
  });

  return (
    <div className="py-5 bg-white m-3 rounded-md dark:!bg-navy-900">
      <div>
        {!machineLoader ? (
          <MachineTable tableData={localStorageData.user.machines ? machineDetails : machineData} machineStatusData={machineStatusData} />
        ) : (
          <TableLoader tableLoaderData={machineTableData} />
        )}
      </div>
    </div>
  );
};

export default Machines;
