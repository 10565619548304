import { BASE_URL_TWO } from "Constant/Constant"
import { useEffect, useState } from "react"
import AddCorporateTopup from "./AddCorporateTopup";
import moment from "moment";

const CorporateTopupHistory = () => {

    const [getCorpoarteHistoryLoading, setGetCorpoarateHistoryLoading] = useState(true);
    const [getCorpoarteHistory, setGetCorpoarateHistory] = useState([]);
    const [open, setOpen] = useState(false)


    const fetchTopups = () => {
        const result = fetch(`${BASE_URL_TWO}corporate-topup`)
            .then((response: any) => response.json(response))
            .catch((error) => console.log("error", error));
        result.then((data) => {
            setGetCorpoarateHistoryLoading(false);
            setGetCorpoarateHistory(data);
        });
    }

    useEffect(() => {
        fetchTopups();
    }, [])

    return (
        <div className=" mt-5">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold text-gray-900">Corporate Topup History</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all the corporate topup history in your account including their name, amount, purpose and date.
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        onClick={() => setOpen(true)}
                        className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                        Add Topup
                    </button>
                </div>
            </div>
            {
                getCorpoarteHistoryLoading ?
                    <h1>Loading...</h1> :
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Amount
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Date
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Purpose
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {getCorpoarteHistory.map((person) => (
                                                <tr key={person.email}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {person.corporate_name}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-black">{Math.ceil(person.amount)}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-black">{moment(person.epoch_time).tz('Etc/GMT-5').format("llll")}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-black">{person.purpose_of_payment}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
            }

            <AddCorporateTopup open={open} setOpen={setOpen} fetchTopups={fetchTopups} />
        </div>
    )
}

export default CorporateTopupHistory