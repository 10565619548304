import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "../../../AppContainer";
import { BASE_URL, BASE_URL_TWO } from "Constant/Constant";

export interface dashboardState {
  userTransactionsData: [];
  userTransactionsLoader: boolean;
  userTransactionError: string;
  clearUserTransactions:any
}

const initialState: dashboardState = {
  userTransactionsData: [],
  userTransactionsLoader: false,
  userTransactionError: "",
  clearUserTransactions:[]
};

const userTransactionSlice = createSlice({
  name: "USER TRANSACTIONS",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.userTransactionsLoader = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.userTransactionError = payload;
    },

    setUserTransactions: (state, { payload }: PayloadAction<any>) => {
      state.userTransactionsData = payload;
    },
    clearUserTransactions: (state) => {
      state.userTransactionsData = [];
    },
  },
});

// AppThunk sets the type definitions for the dispatch method
export const getUserTransactions = (machineId: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setUserTransactions([])); // Clear the userTransactionsData by setting it to an empty array

    try {
      // const baseURL: string = `${BASE_URL_TWO}userTransactionsByMachine/${machineId}`;
      const baseURL: string = `${BASE_URL_TWO}userTransactionsByMachine/${machineId}`;

      const res = await axios({
        method: "get",
        url: baseURL,
      });

      dispatch(setLoading(false));
      if (res.data.status === 200) {
        dispatch(setUserTransactions(res.data.data));
      }
    } catch (error: any) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const { setLoading, setErrors, setUserTransactions } =
  userTransactionSlice.actions;

export default userTransactionSlice.reducer;

export const userTransactionSelector = (state: {
  userTransactionStore: dashboardState;
}) => state.userTransactionStore;

//change selector
//change store
//change slice
